import React from 'react';
import styles from './appStoreLink.module.css';
import {logEventWithSessionId} from "../../services/analytics/analyticsService";
import {appStoreLinkClickParams} from "../../services/analytics/events/clickOnButtonProperties";
import {AppStoreLinkProps} from "../../types/analyticsEventsTypes";

function AppStoreLinkComponent({ source }: AppStoreLinkProps) {
  const appStoreLink = "https://apps.apple.com/app/fund-owner/id6448660302"
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    logEventWithSessionId('app_store_link_click', appStoreLinkClickParams(source));
    window.location.href = appStoreLink;
  };

  return (
    <a href={appStoreLink} className={styles['App-store-link']} onClick={handleClick}>
      <img
        className={styles['appstore-icon']}
        src={process.env.PUBLIC_URL + '/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'}
        alt="Download on AppStore"
      />
    </a>
  );
}

export default AppStoreLinkComponent;
