// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions"
import { getAnalytics } from "firebase/analytics";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC_-AD4D-gbnVWvocxIjP75KyQb0u1MX2Y",
    authDomain: "fund-owner.firebaseapp.com",
    databaseURL: "https://fund-owner-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "fund-owner",
    storageBucket: "fund-owner.appspot.com",
    messagingSenderId: "239966540030",
    appId: "1:239966540030:web:3afeb5b92c5f22558115af",
    measurementId: "G-BHRKYN9VTC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const database = getDatabase(app);
export const functions = getFunctions(app)
export const analytics = getAnalytics();