import React, {useState, useEffect} from "react";
import styles from "./screenshots.module.css";
import Modal from "react-modal";

function ScreenshotsComponent() {

  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openModal = (imagePath: string) => {
    setCurrentImage(imagePath);
    setIsOpen(true);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => { document.body.style.overflow = 'unset' }; // cleanup function
  }, [isOpen]);

  return (
    <section className={styles.screenshotContainer}>
      <div className={styles.screenshotRow}>
        <img onClick={() => openModal('/fund-replica.png')} src={process.env.PUBLIC_URL + '/fund-replica.png'}
             className={styles.screenshot} alt="Screenshot 1"/>
        <img onClick={() => openModal('/own-budget.png')}
             src={process.env.PUBLIC_URL + '/own-budget.png'} className={styles.screenshot}
             alt="Screenshot 2"/>
        <img onClick={() => openModal('/weeks-52.png')} src={process.env.PUBLIC_URL + '/weeks-52.png'}
             className={styles.screenshot} alt="Screenshot 3"/>
        <img onClick={() => openModal('/wealth-observer.png')} src={process.env.PUBLIC_URL + '/wealth-observer.png'}
             className={styles.screenshot} alt="Screenshot 4"/>
      </div>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            content: {
              // position: 'relative',
              border: 'none',
              background: 'none',
              overflow: 'visible',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px'
            }
          }}
          contentLabel="Example Modal"
        >
          <img
            src={process.env.PUBLIC_URL + currentImage}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              cursor: 'pointer'
            }}
            onClick={() => setIsOpen(false)}
            alt="Modal Content"
          />
        </Modal>
      )}
    </section>
  )
}

export default ScreenshotsComponent;
