import React from 'react';
import styles from './last-section.module.css';

function LastSectionComponent() {
  return (
    <section className={styles.additionalSection}>
      {/*<div className={styles.lastSectionRow}>*/}
        {/*<div className={styles.imageContainer}>*/}
        {/*  <img src={process.env.PUBLIC_URL + '/thinking.svg'} alt="Thinking"/>*/}
        {/*  <div>*/}
        {/*    <a href="https://storyset.com/people">People illustrations by Storyset</a>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className={styles.textContainer}>*/}
        {/*  <div className={styles.halfSection}>*/}
        {/*    <p className={styles.textBig}>Create Your Own Fund, focused on popular indices and make informed*/}
        {/*      investment decisions with the Fund Owner app. Download now and start building your fund today!</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
      {/*</div>*/}
      <div className={styles.warningTextContainer}>
        <p className={styles.warningText}>Any information posted on the Site and on the App is not an individual
          investment
          recommendation, and the financial instruments or transactions referred to therein may not
          match your investment profile and investment goals (expectations).
          Determination of the compliance of a financial instrument or transaction with your interests,
          investment goals, investment horizon and level of acceptable risk is
          your task. The Site Administration is not responsible for possible losses in case of
          transactions or investments in financial instruments referred to in this
          information, and does not recommend using this information as the sole
          source of information when making an investment decision.</p>
      </div>
    </section>
  )
}

export default LastSectionComponent;
