import React from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.css';
import {urls} from "../../constants/routes";

function HeaderContainer() {

  return (
    <header className={styles.Header}>
      <div className={styles['header-container']}>
        <div className={styles['header-title']}>
          <h1 className={styles.title}>
            <Link to={urls.home} className={styles.linkTitle} title="Fund Owner">Fund Owner</Link>
          </h1>
        </div>
        <div className={styles['links-container']}>
          <div className={styles['header-privacy']}>
            <Link to={urls.home} className={styles.link} title="Home">Home</Link>
          </div>
          <div className={styles['header-privacy']}>
            <Link to={urls.privacyPolicy} className={styles.link} title="Privacy Policy ">Privacy Policy</Link>
          </div>
          <div className={styles['header-contact']}>
            <Link to={urls.contactUs} className={styles.link} title="Contact Us">Contact Us</Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderContainer;