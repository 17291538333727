import React from 'react';
import styles from './promo.module.css';
import AppStoreLinkComponent from "../elements/AppStoreLinkComponent";

function PromoComponent() {
  return (
    <section className={styles.promoContainer}>
      <div className={styles.promoRow}>
        <div className={styles.textContainer}>
          <h2>Create Your Own Fund</h2>
        </div>
        <div className={styles.promoImageContainer}>
          <img src={process.env.PUBLIC_URL + '/savings.svg'} className="savings-image" alt="Savings"/>
          <div>
            <a href="https://storyset.com/people">People illustrations by Storyset</a>
          </div>
        </div>
      </div>
      <div className={styles.secondRowContainer}>
        <div className={styles.featuresContainer}>
          <p>Welcome to Fund Owner - an app that gives you the power to create and manage your personal wealth
            yourself.</p>
          <p>It is intended for those who want to improve their financial literacy.</p>
          <div className={styles.appStoreLinkContainer}>
            <AppStoreLinkComponent source={'home_page'}/>
          </div>
        </div>
        <div className={styles.featuresContainer}>
          <ul>
            <li><strong>Fund Replica:</strong> Our feature allows you to replicate major market indices such as the
              S&P 500, Nasdaq 100 and Dow Jones, paving the way for efficient investment tracking.
            </li>
            <li><strong>52 Weeks:</strong> Systematically build your wealth with our 52 Weeks feature that adds a new
              dimension to your financial planning.
            </li>
            <li><strong>Own Budget:</strong> Take control of your finances with our budget tracking tool. It will help
              you effectively control your monthly expenses and maintain financial discipline.
            </li>
            <li><strong>Wealth Observer:</strong> This tool provides a complete view of your assets and liabilities,
              allowing you to analyze your financial health and make informed decisions about the future.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default PromoComponent;
