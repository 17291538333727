import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
import {EventParams} from "../../types/analyticsEventsTypes";

export const getSessionId = (): string => {
  let sessionId = localStorage.getItem('sessionId') || 'fallback-session-id';

  if (!sessionId) {
    sessionId = `sess-${Math.random().toString(36).substr(2, 9)}`;
    localStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
}

export const logEventWithSessionId = (eventName: string, params?: {[key: string]: EventParams}): void => {
  const sessionId = getSessionId();
  logEvent(analytics, eventName, {
    ...params,
    session_id: sessionId,
  });
}
