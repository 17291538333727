import {EventParams} from "../../../types/analyticsEventsTypes";

export const homePageViewParams = (): EventParams => {
  return {
    page_title: 'Home Page',
    // Дополнительные параметры
  };
}

export const privacyPolicyViewParams = (): EventParams => {
  return {
    page_title: 'Privacy Policy',
    // Дополнительные параметры
  };
}

export const contactUsViewParams = (): EventParams => {
  return {
    page_title: 'Contact Us Page',
    // Дополнительные параметры
  };
}