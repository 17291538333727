import React from 'react';
import { Link } from 'react-router-dom';
import AppStoreLinkComponent from '../elements/AppStoreLinkComponent';
import styles from './footer.module.css';
import {urls} from "../../constants/routes";

function FooterComponent() {

  return (
    <footer className={styles.Footer}>
      <div className={styles['footer-container']}>
        <div className={styles['footer-block']}>
          <div className={styles['footer-text-block']}>
            <h1 className={styles['footer-title']}>
              <Link to={urls.home} className={styles.linkTitle} title="Fund Owner">Fund Owner</Link>
            </h1>
            <p className={styles['copyright-info']}>
              2024 © manifity (Mikhail Barinov)
            </p>
          </div>
        </div>
        <div className={styles['footer-block']}>
          <Link to={urls.home} className={styles.link}>Home</Link>
          <Link to={urls.privacyPolicy} className={styles.link}>Privacy Policy</Link>
          <Link to={urls.contactUs} className={styles.link}>Contact Us</Link>
        </div>
        <div className={styles['footer-block']}>
          <AppStoreLinkComponent source={'footer'}/>
        </div>
      </div>
      <div className={styles['footer-logo-container']}>
        <img
          className={styles['footer-logo']}
          src={process.env.PUBLIC_URL + '/icon.png'}
          alt="App logo"
        />
      </div>
    </footer>
  );
}

export default FooterComponent;