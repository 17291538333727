import React, { useState } from 'react';
import { saveFeedback } from '../../api/feedback-api';
import styles from './feedback.module.css';
import {logEventWithSessionId} from "../../services/analytics/analyticsService";
import {submitFeedbackClickParams} from "../../services/analytics/events/clickOnButtonProperties";

function FeedbackComponent() {
  const [feedback, setFeedback] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ feedback: '', email: '' });

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  async function handleSubmit() {
    let feedbackError = '';
    let emailError = '';

    if (feedback.trim() === '') {
      feedbackError = 'Please provide some feedback before submitting.';
    }

    if (userEmail && !validateEmail(userEmail)) {
      emailError = 'Please enter a valid email address.';
    }

    if (feedbackError || emailError) {
      setError({ feedback: feedbackError, email: emailError });
      return;
    }

    setLoading(true);
    try {
      await saveFeedback({ feedback, userEmail: userEmail || 'guest' });
      setFeedback('');
      setUserEmail('');
      setError({ feedback: '', email: '' });
      logEventWithSessionId('feedback_submitted', submitFeedbackClickParams());
      alert('Thank you! Feedback is sent successfully.');
    } catch (error) {
      console.log(error);
      logEventWithSessionId('feedback_error', submitFeedbackClickParams());
      setError({ feedback: '', email: 'Error: Failed to send feedback.' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <section className={styles.feedbackSection}>
      <div className={styles.feedbackContainer}>
        <div className={styles.row}>
          <textarea
            className={`${styles.feedbackInput} ${error.feedback && styles.error}`}
            style={{ width: '100%', height: '250px' }}
            placeholder="Leave your feedback here"
            onChange={(e) => {
              setFeedback(e.target.value);
              if (e.target.value.trim() !== '') {
                setError((prevError) => ({ ...prevError, feedback: '' }));
              }
            }}
            value={feedback}
          />
          {error.feedback && <div className={styles.errorText}>{error.feedback}</div>}
        </div>
        <div className={styles.row}>
          <input
            className={`${styles.emailInput} ${error.email && styles.error}`}
            placeholder="Your Email (optional)"
            onChange={(e) => {
              setUserEmail(e.target.value);
              if (validateEmail(e.target.value)) {
                setError((prevError) => ({ ...prevError, email: '' }));
              }
            }}
            value={userEmail}
          />
          {error.email && <div className={styles.errorText}>{error.email}</div>}
        </div>
        <div className={styles.row}>
          <button
            className={styles.submitButton}
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </section>
  );
}

export default FeedbackComponent;
