interface Feedback {
    feedback: string;
    userEmail?: string | null;
}

export function saveFeedback(feedbackData: Feedback): Promise<void> {
  const url = "https://europe-west1-fund-owner.cloudfunctions.net/saveFeedbackDB";

  console.log('Sending feedback data:', feedbackData);

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(feedbackData),
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then(text => {
          throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
        });
      }
    })
    .catch((error) => {
      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        console.error("There was a network error. Please check your internet connection and try again.");
      } else {
        console.error("There was an error!", error);
      }
    });
}