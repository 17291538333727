import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import HeaderContainer from './components/header/header';
import FooterComponent from "./components/footer/footer";
import HomePage from "./pages/home/home";
import PrivacyPolicyPage from "./pages/privacy-policy/privacy-policy";
import ContactUsPage from "./pages/contact-us/contact-us";
import ScrollToTop from './components/scroll-to-top';
import CookieConsent from './components/cookies/cookie-constent';

function App() {
  return (
    <Router>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BHRKYN9VTC"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-BHRKYN9VTC');
          `}
        </script>
      </Helmet>
      <ScrollToTop />
      <div className="App">
        <HeaderContainer />
        <main className="main-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
          </Routes>
        </main>
        <FooterComponent />
      </div>
      <CookieConsent />
    </Router>
  );
}

export default App;