import {EventParams} from "../../../types/analyticsEventsTypes";

export const cookieConsentAcceptParams = (): EventParams => {
  return {
    action: 'accept_cookie_consent',
    category: 'cookie_consent',
    label: 'user_acceptance',
    // Дополнительные параметры, если требуется
  };
}

export const appStoreLinkClickParams = (source: string): EventParams => {
  return {
    action: 'click',
    category: 'app_store_link',
    label: source,
    // Дополнительные параметры по желанию
  };
}

export const submitFeedbackClickParams = (): EventParams => {
  return {
    action: 'click',
    category: 'submit_feedback',
    // Дополнительные параметры по желанию
  };
}