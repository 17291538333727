import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import FeedbackComponent from "../../components/feedback/feedback";
import styles from './contact-us.module.css';
import {logEventWithSessionId} from "../../services/analytics/analyticsService";
import {contactUsViewParams} from "../../services/analytics/events/viewPageProperties";

function ContactUsPage() {
  useEffect(() => {
    logEventWithSessionId('page_view', contactUsViewParams());
  }, []);

  return (
    <div className={styles.contactUs}>
      <Helmet>
        <title>Fund Owner - Contact Us</title>
        <meta name="description" content="Get in touch with the Fund Owner team. We're here to answer any questions or concerns regarding your personal investment fund. Contact us today!" />
        <meta name="keywords" content="Fund Owner, contact, investment fund, questions, support" />
      </Helmet>
      <div className={styles.contactUsContainer}>
        <div>
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className={styles.contactUsContent}>
        <p>We would love to hear your feedback! Any thoughts, suggestions, or comments are welcome.</p>
        <FeedbackComponent />
      </div>
    </div>
  );
}

export default ContactUsPage;