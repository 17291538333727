import React, {useState, useEffect} from 'react';
import styles from './cookie-consent.module.css';
import {logEventWithSessionId} from "../../services/analytics/analyticsService";
import {cookieConsentAcceptParams} from "../../services/analytics/events/clickOnButtonProperties";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
    logEventWithSessionId('accept_cookie_consent', cookieConsentAcceptParams());
  };

  if (!isVisible) return null;

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerText}>
        <p>We use cookies and analytics to improve your experience on our website. Your data was not used for
          personalization. By continuing to use the site, you consent to the use of cookies and analytics.</p>
        <p>Find out more about our <a href="/privacy-policy" className={styles.privacyPolicyLink}>Privacy Policy</a></p>
      </div>
      <div>
        <button className={styles.acceptButton} onClick={handleAccept}>Accept</button>
      </div>
    </div>
  );
};

export default CookieConsent;