import React, {useEffect} from 'react';
import styles from './home.module.css';
import PromoComponent from "../../components/promo/promo";
import ScreenshotsComponent from "../../components/screenshots/screenshots";
import LastSectionComponent from "../../components/last-section/last-section";
import { Helmet } from "react-helmet";
import {logEventWithSessionId} from "../../services/analytics/analyticsService";
import {homePageViewParams} from "../../services/analytics/events/viewPageProperties";

function HomePage() {
  useEffect(() => {
    // Отправка события просмотра главной страницы
    logEventWithSessionId('page_view', homePageViewParams());
  }, []);

  return (
    <div className={styles.homePage}>
      <Helmet>
        <title>Fund Owner - Create Your Own Fund</title>
        <meta name="description" content="Fund Owner is an intuitive app that lets you create and manage your own investment fund, replicating popular indices such as S&P 500, Nasdaq 100, and Dow Jones. Start building your fund today!" />
        <meta name="keywords" content="investment, fund, builder, S&P 500, Nasdaq 100, Dow Jones, personal finance, stock market, investing" />
      </Helmet>
      <PromoComponent/>
      <ScreenshotsComponent />
      <LastSectionComponent />
    </div>
  );
}

export default HomePage;